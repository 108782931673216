import { CheckoutQuery as SourceCheckoutQuery } from 'SourceQuery/Checkout.query';
import { telephoneSanitizer } from 'Util/Global';
import { Field } from 'Util/Query';

/** @namespace Sofacompany/Query/Checkout/Query/CheckoutQuery */
export class CheckoutQuery extends SourceCheckoutQuery {
    _getOrderField() {
        return new Field('order')
            .addFieldList([
                'id',
                'order_id',
                'transaction_id',
                'resultCode',
                this._getAction(),
                'Active3D',
                'threeDS2'
            ]);
    }

    _getAction() {
        return new Field('action')
            .addFieldList([
                'paymentMethodType',
                'url',
                'paymentData',
                'authorisationToken',
                'token',
                'subtype',
                this._getActionData(),
                'method',
                'type'
            ]);
    }

    _getActionData() {
        return new Field('data')
            .addFieldList([
                'MD',
                'PaReq',
                'TermUrl'
            ]);
    }

    convertToCart(saved_id, guest_id) {
        return new Field('convertToCart')
            .addArgument('guest_quote_id', 'String', guest_id)
            .addArgument('saved_quote_id', 'String', saved_id)
            .addFieldList([
                'is_expired',
                'items_not_in_stock',
                'price_changed',
                'quote_id',
                this.getAddressRestoreInformation()
            ]);
    }

    convertToQuote(guest_id, info = undefined) {
        const field = new Field('convertToQuote')
            .addArgument('guest_quote_id', 'String!', guest_id);

        if (info !== undefined) {
            field.addArgument('info', 'SendBasketInfoInput', info);
        }

        return field;
    }

    getSaveAddressInformation(addressInformation, guestCartId) {
        const modAddressInformation = telephoneSanitizer(addressInformation);
        const mutation = new Field('saveAddressInformation')
            .addArgument('addressInformation', 'SaveAddressInformation!', modAddressInformation)
            .addFieldList(this._getSaveAddressInformationFields());

        this._addGuestCartId(guestCartId, mutation);

        return mutation;
    }

    getSetBillingAddressOnCart(input) {
        const modInput = telephoneSanitizer(input, 'address');
        return new Field('s_setBillingAddressOnCart')
            .addArgument('input', 'S_SetBillingAddressOnCartInput!', modInput)
            .addField(this._getCartField())
            .setAlias('billingAddress');
    }

    getAddressRestoreInformation() {
        return new Field('address_information')
            .addFieldList([
                'firstname',
                'lastname',
                'telephone',
                'postcode',
                'email',
                'country_id',
                'region',
                'city',
                'street',
                'floor',
                'vat_id',
                'company',
                'delivery_comment',
                this.getSelectedShippingMethod()
            ]);
    }

    getSelectedShippingMethod() {
        return new Field('selected_shipping_method')
            .addFieldList([
                'carrier_code',
                'carrier_title',
                'method_code',
                'method_title',
                this._getAmount(),
                this._getAmountInclTax()
            ]);
    }

    _getAmount() {
        return new Field('amount')
            .addFieldList([
                'value'
            ]);
    }

    _getAmountInclTax() {
        return new Field('amount_incl_tax')
            .addFieldList([
                'value'
            ]);
    }

    getRestrictedZipCodes() {
        return new Field('getRestrictedZipCodes')
            .addFieldList([
                'range_from',
                'range_to'
            ]);
    }
}

export default new CheckoutQuery();
