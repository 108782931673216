import { Field } from 'Util/Query';

/** @namespace Sofacompany/Query/Newsletter/Query/NewsletterQuery */
export class NewsletterQuery {
    getMutation(options) {
        const { email, firstname } = options;

        return new Field('signupNewsletter')
            .addArgument('email', 'String!', email)
            .addArgument('firstname', 'String!', firstname)
            .addField('status');
    }

    getConfirmNewsletterMutation(options) {
        const { id, code } = options;

        return new Field('newsletterConfirm')
            .addArgument('id', 'Int!', id)
            .addArgument('code', 'String!', code)
            .addField('status');
    }

    getNewsletterConfig() {
        return new Field('getClickDimensionsNewsletterConfig')
            .addField('script1')
            .addField('script2');
    }
}

export default new NewsletterQuery();
