import { FILE_TYPE } from '@scandipwa/scandipwa/src/component/Field/Field.config';
import PropTypes from 'prop-types';

import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';

import Field from './Field.component';
import {
    CHECKBOX_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TELEPHONE_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE,
    VAT_TYPE
} from './Field.config';

/** @namespace Sofacompany/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        type: PropTypes.oneOf([
            TEXT_TYPE,
            NUMBER_TYPE,
            TEXTAREA_TYPE,
            PASSWORD_TYPE,
            RADIO_TYPE,
            CHECKBOX_TYPE,
            SELECT_TYPE,
            TELEPHONE_TYPE,
            VAT_TYPE
        ]).isRequired
    };

    __construct(props) {
        super.__construct(props);

        const { checked } = props;
        const value = this.getInitialPropsValue();

        this.state = {
            value,
            checked,
            validationMessage: '',
            validationStatus: null,
            eventId: ''
        };
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue, checked: prevChecked, isSubmitted: prevSubmitted } = prevProps;
        const {
            value: currentValue,
            checked: currChecked,
            type,
            id,
            isSubmitted
        } = this.props;
        const { eventId } = this.state;

        if (prevValue !== currentValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: currentValue });
        }
        if ((type === CHECKBOX_TYPE || type === RADIO_TYPE) && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }

        if (eventId === id || prevSubmitted !== isSubmitted) {
            this.updateValidationStatus();
            this.setValidationMessage(prevProps);
        }
    }

    onChange(event) {
        const { type } = this.props;
        this.setState({ eventId: event?.target?.name });

        if (typeof event === 'string' || typeof event === 'number') {
            return this.handleChange(event);
        }

        if (event.currentTarget.value.length <= 0) {
            this.setState({
                validationStatus: null
            });
        }

        this.updateValidationStatus();

        if (type === FILE_TYPE) {
            return this.handleChange(event.target.value, false, event.target.files[0]);
        }

        return this.handleChange(event.target.value);
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { customValidationStatus, ...otherProps } = this.props;

        return (
            <Field
              { ...otherProps }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldContainer;
