import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NewsletterQuery from 'Query/Newsletter.query';
import { showNotification } from 'Store/Notification/Notification.action';
import DataContainer from 'Util/Request/DataContainer';

import Newsletter from './Newsletter.component';

export const NewsletterDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Newsletter/Newsletter.dispatcher'
);

export const ContentBlockDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ContentBlock/ContentBlock.dispatcher'
);

/** @namespace Sofacompany/Component/Newsletter/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isNewsletterVisible: state.NewsletterReducer.isNewsletterVisible,
    newsletterContent: state.ContentBlockReducer.content.newsletterContent
});

/** @namespace Sofacompany/Component/Newsletter/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    sendNewsletterSignup: (options) => NewsletterDispatcher.then(
        /** @namespace Component/Newsletter/Container/then */
        ({ default: dispatcher }) => dispatcher.requestNewsletterSignup(dispatch, options)
    ),
    requestContentBlock: (options) => ContentBlockDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getData(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Sofacompany/Component/Newsletter/Container/NewsletterContainer */
export class NewsletterContainer extends DataContainer {
    static propTypes = {
        sendNewsletterSignup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        requestContentBlock: PropTypes.func.isRequired,
        newsletterContent: PropTypes.string
    };

    static defaultProps = {
        newsletterContent: null
    };

    state = {
        isLoading: false,
        scriptsInjected: false
    };

    componentDidMount() {
        const {
            requestContentBlock,
            newsletterContent

        } = this.props;

        const options3 = {
            identifier: 'newsletter-signup-text',
            alias: 'newsletterContent'
        };

        if (!newsletterContent) {
            requestContentBlock(options3);
        }
    }

    async injectNewsletterScripts() {
        this.fetchData(
            [NewsletterQuery.getNewsletterConfig()],
            (data) => {
                if (data.getClickDimensionsNewsletterConfig.script1) {
                    const script1 = document.createElement('script');
                    script1.type = 'text/javascript';
                    script1.innerHTML = data.getClickDimensionsNewsletterConfig.script1;
                    document.getElementsByTagName('head')[0].appendChild(script1);
                }

                if (data.getClickDimensionsNewsletterConfig.script2) {
                    const script2 = document.createElement('script');
                    script2.type = 'text/javascript';
                    script2.src = data.getClickDimensionsNewsletterConfig.script2;
                    document.getElementsByTagName('head')[0].appendChild(script2);
                }

                if (
                    data.getClickDimensionsNewsletterConfig.script1
                    && data.getClickDimensionsNewsletterConfig.script2
                ) {
                    this.setState({
                        scriptsInjected: true
                    });
                }
            }
        );
    }

    render() {
        const { scriptsInjected } = this.state;

        this.injectNewsletterScripts();

        if (!scriptsInjected) {
            return null;
        }

        return (
            <Newsletter
              { ...this.state }
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterContainer);
