import {
    CLEAR_MEILISEARCH_RESULTS,
    UPDATE_MEILISEARCH,
    UPDATE_MEILISEARCH_CATEGORIES,
    UPDATE_MEILISEARCH_HOME,
    UPDATE_MEILISEARCH_LOAD_STATUS,
    UPDATE_MEILISEARCH_PAGES
} from './MeiliSearch.action';

/** @namespace Sofacompany/Store/MeiliSearch/Reducer/getInitialState */
export const getInitialState = () => ({
    productsInMeiliSearch: [],
    categoriesInMeiliSearch: [],
    pagesInMeiliSearch: [],
    homeInMeiliSearch: [],
    metaInMeiliSearch: {},
    filtersInMeiliSearch: {},
    isLoading: true
});

/** @namespace Sofacompany/Store/MeiliSearch/Reducer/MeiliSearchReducer */
export const MeiliSearchReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_MEILISEARCH_HOME:
        return {
            ...state,
            homeInMeiliSearch: action.result
        };

    case UPDATE_MEILISEARCH:
        const {
            result: {
                hits,
                query,
                filters = [],
                skip_filter,
                filter_only
            }
        } = action;

        // eslint-disable-next-line fp/no-let
        const formattedFilters = {};

        filters.map((filter) => {
            const {
                key, label, type, options
            } = filter;

            formattedFilters[key] = {
                attribute_code: key,
                attribute_label: label,
                attribute_options: {},
                attribute_type: type === 'other' ? 'select' : type,
                attribute_values: []
            };

            options.map((option) => {
                const { name, value } = option;
                const formattedValue = value.replace(`${key}:`, '');

                formattedFilters[key].attribute_options[formattedValue] = {
                    label: name,
                    swatch_data: null,
                    value_string: formattedValue
                };

                formattedFilters[key].attribute_values.push(formattedValue);
            });
        });

        // Hide fabric samples from search results
        const filteredResult = (hits !== undefined ? hits.filter((item) => parseInt(item?.price, 10) > 1) : []);

        if (filter_only) {
            return {
                ...state,
                filtersInMeiliSearch: formattedFilters
            };
        }

        if (skip_filter) {
            return {
                ...state,
                productsInMeiliSearch: filteredResult,
                metaInMeiliSearch: query
            };
        }

        return {
            ...state,
            productsInMeiliSearch: filteredResult,
            metaInMeiliSearch: query,
            filtersInMeiliSearch: formattedFilters
        };

    case UPDATE_MEILISEARCH_CATEGORIES:
        const {
            result: {
                categories
            }
        } = action;

        return {
            ...state,
            categoriesInMeiliSearch: (categories !== undefined ? categories : [])
        };

    case UPDATE_MEILISEARCH_PAGES:
        const {
            result: {
                pages
            }
        } = action;

        return {
            ...state,
            pagesInMeiliSearch: pages
        };

    case UPDATE_MEILISEARCH_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case CLEAR_MEILISEARCH_RESULTS:
        return {
            ...state,
            productsInMeiliSearch: getInitialState().productsInMeiliSearch
        };

    default:
        return state;
    }
};

export default MeiliSearchReducer;
